<template>
    <div class="agents">
        <div class="agents-grid">
            <template v-for="agent in agents" :key="agent.id">
                <div class="agent-box">
                    <div class="agent-image">
                        <img :src="agent.image" alt="">
                        <div class="agent-id">{{agent.id}}</div>
                    </div>
                    <div class="agent-data">
                        <p style="font-size: 18px; color:var(--yellow)">{{agent.name}}</p>
                        <p>{{agent.phone}}</p>
                        <p v-if="!agent.user" style="color:var(--danger)">אין משתמש</p>
                        <p v-else>קיים משתמש</p>
                    </div>
                    <div class="agent-actions">
                        <el-button v-if="!agent.user" type="info" @click="create_user(agent)">צור משתמש</el-button>
                        <el-button v-if="agent.user" type="primary" @click="open_profile(agent)">פרופיל</el-button>
                        <el-button v-if="agent.user" type="primary" style="width:100%;" @click="open_clients(agent)">לקוחות</el-button>
                    </div>
                </div>
            </template>
        </div>
        <div class="data-panel">
            <CreateAgent :agent="selected_agent" v-if="show_create_user" @finilize_user= "finilize_user"/>
            <AgentProfile :agent="selected_agent" v-if="show_profile" @finilize_profile = 'finilize_user'/>
            <AgentClients :agent="selected_agent" v-if="show_clients"/>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../firebase/config'
import { onMounted } from '@vue/runtime-core';
import CreateAgent from '../../components/Admin/CreateAgent.vue';
import AgentProfile from '../../components/Admin/AgentProfile.vue';
import AgentClients from '../../components/Admin/AgentClients.vue';

export default {
    components:{CreateAgent, AgentProfile, AgentClients},
    setup(){
    const agents = ref([]);
    const selected_agent = ref(null);
    const show_create_user = ref(false);
    const show_profile = ref(false);
    const show_clients = ref(false);

    const get_agents_users = async () => {
        for(let i =0; i <agents.value.length; i++){
            let agent = agents.value[i]
            if(agent.uid && agent.user){
                const doc = await projectFirestore.collection('Users').doc(agent.uid).get()
                if(doc.exists){
                    agents.value[i].image = doc.data().profile_image
                }
            }
        }
    }

    const get_agents = async () => {
        const docs = await projectFirestore.collection('Agents').get();
        if(!docs.empty){
            agents.value = docs.docs.map(doc => doc.data())
            agents.value = agents.value.sort((aa, bb) => {
                return aa.id - bb.id
            })
            get_agents_users();
        }
    }

    const finilize_user = async () => {
        await get_agents();
        show_create_user.value = false;
        show_profile.value = false;
    }

    const create_user = (agent) => {
        selected_agent.value = agent;
        show_create_user.value = true; 
        show_profile.value = false;
        show_clients.value = false;
    }

    const open_profile = (agent) => {
        selected_agent.value = agent;
        show_profile.value = true;
        show_create_user.value = false; 
        show_clients.value = false;
    }

    const open_clients = (agent) => {
        show_clients.value = false;
        setTimeout(() => {
            selected_agent.value = agent;
            show_profile.value = false;
            show_create_user.value = false; 
            show_clients.value = true;
        }, 200);
    }

    onMounted(() => {
        get_agents();
    })
        return{
            agents, selected_agent, create_user, show_create_user, finilize_user,
            show_profile, open_profile,show_clients, open_clients
        }
    }
}
</script>

<style scoped>
.agents{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}
.agents-grid{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-auto-rows: 120px;
    gap: 5px;
}
.agent-box{
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.agent-image{
    position: relative;
    flex-shrink: 0;
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.agent-image img{
    max-width: 80%;
    max-height: 80%;
    border-radius: 5px;
}
.agent-id{
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: var(--main);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
}
.agent-data{
    flex-shrink: 0;
    width: 45%;
    height: 100%;
    color: var(--light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.agent-actions{
    flex-shrink: 0;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1400px) {
    .agents-grid{
        grid-template-columns: 100%;
        grid-auto-rows: 120px;

    }
}
@media only screen and (max-width: 700px) {
    .agents{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 40% 100%;
        overflow-y: auto;
        gap: 10px;
    }
    ::-webkit-scrollbar{
        width: 0;
    }
}
</style>